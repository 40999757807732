<div class="back-navigation" appActivitySection="GiftCard">
  <img src="themes/boston-pizza/assets/images/back_button.svg" appUserActivityClickTracking="GiftCardClose">
</div>

<div class="box" appUserActivityClickTracking="GiftCardClose">
  <ng-container>
    <img class="logo" src="themes/boston-pizza/assets/images/logo_service_centre.svg" alt="">
<!--    <div class="title" [innerHTML]="'serviceCenter.sections.gift.title' | translate"></div>-->
    <div class="title">{{ 'serviceCenter.sections.gift.title' | translate }}</div>
    <img class="qrImg" src="themes/boston-pizza/assets/images/qr_gift_cards.svg" alt="">
  </ng-container>
</div>
