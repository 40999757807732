<div class="controls">
  @if (table$ | async; as table) {
    <button class="toggle" (click)="handleToggleTable($event)" [class.active]="open() === 'table'">
      Table {{ table.tableName }}
    </button>
  }

  <button class="toggle" (click)="handleToggleApp($event)" [class.active]="open() === 'app'">
    {{ appName }} | {{ appEnv }} | v{{ appVersion }}
  </button>

  <button class="red" (click)="handleReload($event)">
    Restart
  </button>

  @if (updateEnabled) {
    @if ((updateStatus$ | async); as updateStatus) {
      @if (updateStatus.state === 'ready') {
        <button class="yellow" (click)="handleUpdate($event)">
          Update {{ updateStatus.latestBuild.version === appVersion ? 'to latest' : 'to v' + updateStatus.latestBuild.version }}
        </button>
      } @else {
        <button class="green" (click)="handleCheckForUpdate($event)">
          Check for updates
        </button>
      }
    } @else {
      <button class="green" (click)="handleCheckForUpdate($event)">
        Check for updates
      </button>
    }
  }
</div>

@if (open() === 'app') {
  <div class="content">
    <core-debug-ui-card label="App Information">
      <core-debug-app></core-debug-app>
    </core-debug-ui-card>
    <core-debug-ui-card label="Device Information">
      <core-debug-device></core-debug-device>
    </core-debug-ui-card>
  </div>
}

@if (open() === 'table') {
  <div class="content">
    <core-debug-ui-card label="Table Information">
      <core-debug-table></core-debug-table>
    </core-debug-ui-card>
  </div>
}
