<core-debug-ui-size>
  v{{ appVersion }}
  @if (updateStatus$ | async; as status) {
    @if (status.state === 'ready') {
      (<span style="color: #f2f702">{{ status.latestBuild.version === appVersion ? 'New build' : 'v' + status.latestBuild.version }}</span>)
    }
  }
</core-debug-ui-size>
<core-debug-ui-items>
  <core-debug-ui-field label="Name">{{ appName }}</core-debug-ui-field>
  <core-debug-ui-field label="Environment">{{ appEnv }}</core-debug-ui-field>
  <core-debug-ui-field label="Version">v{{ appVersion }}</core-debug-ui-field>
  <core-debug-ui-field label="Self Update">
    {{ updateEnabled ? 'Enabled' : 'Disabled' }}
  </core-debug-ui-field>
  <core-debug-ui-field label="Uptime">{{ uptime$ | async }} - {{ appStartDate | date: 'yyyy-MM-dd hh:mm' }}</core-debug-ui-field>
  <core-debug-ui-field label="Date">{{ date$ | async }}</core-debug-ui-field>
</core-debug-ui-items>

<core-debug-ui-items label="Call Waiter">
  <core-debug-ui-field label="Status">
    @if (callWaiter$ | async) {
      <span style="color: #2df702">Active</span>
    } @else {
      Inactive
    }
  </core-debug-ui-field>
  <core-debug-ui-field label="To Repeat Status">
    @if (callWaiterToRepeat$ | async) {
      <span style="color: #2df702">Active</span>
    } @else {
      Inactive
    }
  </core-debug-ui-field>
  <core-debug-ui-field label="To Pay Status">
    @if (callWaiterToPay$ | async) {
      <span style="color: #2df702">Active</span>
    } @else {
      Inactive
    }
  </core-debug-ui-field>
</core-debug-ui-items>

@if (updateEnabled) {
  <core-debug-ui-items label="Self Update Strategies">
    <core-debug-ui-field label="Check Strategy">
      {{ updateCheckStrategyTitle }}
    </core-debug-ui-field>
    <core-debug-ui-field label="Activate Strategy">
      {{ updateActivateStrategyTitle }}
    </core-debug-ui-field>
  </core-debug-ui-items>
}

<core-debug-ui-items label="Network">
  <core-debug-ui-field label="Status">
    @if (networkStatus$ | async) {
      <span style="color: #2df702">Online</span>
    } @else {
      <span style="color: #f12f2f">Offline</span>
    }
  </core-debug-ui-field>

  <core-debug-ui-field label="Connections">
    {{ (networkConnectionCount$ | async) ?? 0 }}
  </core-debug-ui-field>

  @if (networkLastConnectionAt$ | async; as lastConnectionAt) {
    <core-debug-ui-field label="Last Connection">
      {{ lastConnectionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }

  @if (networkLastDisconnectionAt$ | async; as lastDisconnectionAt) {
    <core-debug-ui-field label="Last Disconnection">
      {{ lastDisconnectionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }
</core-debug-ui-items>

<core-debug-ui-items label="Websocket">
  <core-debug-ui-field label="Status">
    @if (websocketStatus$ | async) {
      <span style="color: #2df702">Online</span>
    } @else {
      <span style="color: #f12f2f">Offline</span>
    }
  </core-debug-ui-field>

  <core-debug-ui-field label="Connections">
    {{ (websocketConnectionCount$ | async) ?? 0 }}
  </core-debug-ui-field>

  @if (websocketLastConnectionAt$ | async; as lastConnectionAt) {
    <core-debug-ui-field label="Last Connection">
      {{ lastConnectionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }

  @if (websocketLastDisconnectionAt$ | async; as lastDisconnectionAt) {
    <core-debug-ui-field label="Last Disconnection">
      {{ lastDisconnectionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }
</core-debug-ui-items>

<core-debug-ui-items label="User Interection">
  <core-debug-ui-field label="Status">
      @if (interactionStatus$ | async) {
      <span style="color: #f2f702">Idle</span>
    } @else {
      <span style="color: #2df702">Active</span>
    }
  </core-debug-ui-field>

  <core-debug-ui-field label="Activate idle">
      {{ interactionIdleDelay === 0 ? 'Disabled' : 'After ' + interactionIdleDelay + ' ms.' }}
  </core-debug-ui-field>

    @if (interactionLastInteractionAt$ | async; as lastInteractionAt) {
    <core-debug-ui-field label="Last Interaction">
      {{ lastInteractionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }

    @if (interactionLastStartInteractionAt$ | async; as lastStartInteractionAt) {
    <core-debug-ui-field label="Last Start Interaction">
      {{ lastStartInteractionAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }

    @if (interactionLastStartIdleAt$ | async; as lastStartIdleAt) {
    <core-debug-ui-field label="Last Start Idle">
      {{ lastStartIdleAt | date: 'yyyy-MM-dd hh:mm:ss' }}
    </core-debug-ui-field>
  }
</core-debug-ui-items>

@if (updateEnabled) {
  @if (updateStatus$ | async; as status) {
    <core-debug-ui-items label="Update">
      @if (status.state === 'actual') {
        <core-debug-ui-field label="Status">
          <span style="color: #2df702">Actual</span>
        </core-debug-ui-field>
        <core-debug-ui-field label="Current Build Hash">
          {{ status.currentBuild.hash }}
        </core-debug-ui-field>
        <core-debug-ui-field label="Last Check">
          {{ status.date | date: 'yyyy-MM-dd hh:mm:ss' }}
        </core-debug-ui-field>
      } @else if (status.state === 'available') {
        <core-debug-ui-field label="Status">
          <span style="color: #f2f702">Update available</span>
        </core-debug-ui-field>
        <core-debug-ui-field label="Latest Build Hash">
          {{ status.currentBuild.hash }}
        </core-debug-ui-field>
        <core-debug-ui-field label="Last Check">
          {{ status.date | date: 'yyyy-MM-dd hh:mm:ss' }}
        </core-debug-ui-field>
      } @else if (status.state === 'ready') {
        <core-debug-ui-field label="Status">
          <span style="color: #f2f702">Update ready {{ status.latestBuild.version === appVersion ? 'to latest' : 'to v' + status.latestBuild.version }}</span>
        </core-debug-ui-field>
        <core-debug-ui-field label="Latest Build Hash">
          {{ status.latestBuild.hash }}
        </core-debug-ui-field>
        <core-debug-ui-field label="Current Build Hash">
          {{ status.currentBuild.hash }}
        </core-debug-ui-field>
        <core-debug-ui-field label="Last Check">
          {{ status.date | date: 'yyyy-MM-dd hh:mm:ss' }}
        </core-debug-ui-field>
      } @else if (status.state === 'failed') {
        <core-debug-ui-field label="Last Check">
          {{ status.date | date: 'yyyy-MM-dd hh:mm:ss' }}
        </core-debug-ui-field>
        <core-debug-ui-field label="Status">
          <span style="color: #f12f2f">Update failed</span>
        </core-debug-ui-field>
      }
    </core-debug-ui-items>
  }
}
