import { APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import { CORE_APP_ENV, CORE_APP_NAME, CORE_APP_VERSION, CORE_DEBUG, CORE_FEATURE_TOGGLE } from './core.tokens';
import {
  DWallIntercom,
  InteractionService,
  NetworkService,
  UpdateService,
  WatchdogService,
  WebsocketService,
} from './lib/services';

export const provideCoreInitialize = () => (
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (
      update: UpdateService,
      network: NetworkService,
      interaction: InteractionService,
      websocket: WebsocketService,
      intercom: DWallIntercom,
      watchdog: WatchdogService,
      name: string,
      version: string,
      environment: string,
      debug: boolean,
      featureToggle: unknown,
      platformId: unknown,
    ) => () => {
      const log = watchdog.tag('App', 'red');

      log.info('Initializing', {
        name,
        version,
        environment,
        debug,
        featureToggle,
        platform: platformId,
      });

      update.initialize();
      network.initialize();
      interaction.initialize();
      intercom.initialize();
      websocket.initialize();
    },
    deps: [
      UpdateService,
      NetworkService,
      InteractionService,
      DWallIntercom,
      WebsocketService,
      WatchdogService,
      CORE_APP_NAME,
      CORE_APP_VERSION,
      CORE_APP_ENV,
      CORE_DEBUG,
      CORE_FEATURE_TOGGLE,
      PLATFORM_ID,
    ],
  }
);
